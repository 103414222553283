.promotion-button-container {
  @apply flex items-center justify-center text-primaryA hover:text-primaryADark;
}

.promotion-button-container:hover .pomotion-button-image {
  @apply shadow-card;
}

.pomotion-button-image {
  @apply w-8 h-8 rounded-full bg-secondaryB mr-2;
}

.promotion-button-label {
  @apply flex items-center mr-8 font-display text-base text-primaryC leading-6 tracking-tight hover:text-primaryA focus:outline-none;
  max-width: 11rem;
}

.promoted-room-container {
  @apply flex items-center justify-center;
}