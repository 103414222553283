.progress-container {
  @apply flex flex-row mb-10;
}

.progress {
  @apply flex-grow rounded mr-1 flex justify-between bg-no-repeat cursor-pointer;
  height: 4px;
  /* background-image: -webkit-linear-gradient(left, 
    rgba(255,255,255,1) 0%,
    rgba(255,255,255,1) 50%,
    rgba(88, 89, 104,.5) 50.001%,
    rgba(88, 89, 104,.5) 100%
  );
  background-size: 200%;
  background-color: rgba(255, 255, 255, 0.3);
  background-position: 100% 50%;
  animation-timing-function: linear;
  animation-duration: 5.9s */
}

.progress::-moz-progress-bar { background: #fff; }
.progress::-webkit-progress-value { background: #fff; }
.progress { color: #fff; }

.quiz-progress-container {
  @apply col-span-8 flex items-center mb-4 h-6 relative;
}

.quiz-progress-text {
  @apply font-paragraph text-base text-darkGrey;
}