.coins-container {
  @apply col-span-full mt-28 px-8 py-10 shadow-card rounded-lg;
}

.coins-section {
  @apply flex flex-wrap;
}

.coins-item {
  @apply col-span-3 rounded-lg bg-gradient-to-r from-primaryADark to-primaryA cursor-pointer;
}

/* @media only screen and (min-width: 1700px) {
  .coins-section {
    @apply grid-cols-12;
  }
} */

.shop-item {
  @apply flex flex-col justify-between rounded-lg bg-center bg-no-repeat bg-cover cursor-pointer bg-gradient-to-r from-primaryADark to-primaryA shadow-card;
}

.stickers-shop-item {
  @apply flex flex-col justify-between rounded-lg bg-center bg-no-repeat bg-cover shadow-card;
}

.avatar-shop-item {
  @apply relative rounded-lg shadow-card py-4 px-6 mr-4 mb-4 cursor-pointer;
}

.avatar-shop-item.in-inventory {
  @apply cursor-default;
}

.avatar-shop-item-image {
  @apply rounded-full w-30;
}

.avatar-shop-item-image.in-inventory {
  @apply rounded-full w-30;
  opacity: 0.4;
}

.avatar-shop-item-price-container {
  @apply mt-3 text-primaryADark text-xl font-display tracking-tighter text-center;
}

.avatar-shop-item-price-container.in-inventory {
  @apply mt-6 text-sm font-bold;
}

.avatar-shop-item-price-container span {
  @apply font-bold;
}

.sticker-bundle-item {
  @apply flex items-center justify-center;
  background-image: radial-gradient(circle at 54% 50%, #a562f5, #2500dc 96%);
}

.tooltip-btn{
  z-index: 20;
  right: 70px;
  top: 60px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.avatar-shop-item:hover .tooltip-btn{
  opacity: 1;
  visibility: visible;
  right:84px;
}