.notifications-container {
  @apply absolute z-30 right-0 mt-2 mb-0 p-0 shadow-card bg-white min-w-48 rounded-lg overflow-hidden flex items-center justify-center flex-col;
}

.notifications-empty {
  @apply flex items-center justify-center flex-col px-10 my-10;
}

.notifications-header {
  @apply flex items-center w-full px-4 ml-12 mt-4 mb-1 mr-10;
}

.notifications-full {
  @apply flex items-center justify-center flex-col;
}

.notifications-item {
  @apply flex flex-col mb-1 py-2 pl-6 pr-12;
}

.notifications-list {
  @apply flex flex-col pb-4 h-full overflow-auto;
  max-height: 80vh;
  scrollbar-width: thin;
}

.notifications-list::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.notifications-list::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.notifications-list::-webkit-scrollbar-thumb
{
	background-color: #6B7280;
	border: 2px solid #6B7280;
}
