.video-upload-container {
  @apply w-full h-0 relative;
  padding-top: 56.25%;
}

.video-upload-content {
  @apply absolute inset-0 flex;
  @apply flex-1 flex-col items-center justify-center;
  @apply bg-secondaryC rounded-lg cursor-pointer;
  @apply border border-transparent hover:border hover:border-secondaryB
}