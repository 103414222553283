/* PT Sans */

@font-face {
	font-family: 'PT Sans';
	src: url('../fonts/PT_Sans/PTSans-Regular.woff2')  format('woff2'),
		url('../fonts/PT_Sans/PTSans-Regular.ttf')  format('truetype');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('../fonts/PT_Sans/PTSans-Italic.woff2')  format('woff2'),
		url('../fonts/PT_Sans/PTSans-Italic.ttf')  format('truetype');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('../fonts/PT_Sans/PTSans-Bold.woff2')  format('woff2'),
		url('../fonts/PT_Sans/PTSans-Bold.ttf')  format('truetype');
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('../fonts/PT_Sans/PTSans-BoldItalic.woff2')  format('woff2'),
		url('../fonts/PT_Sans/PTSans-BoldItalic.ttf')  format('truetype');
	font-style: italic;
	font-weight: 700;
	font-display: swap;
}

/* PT_Mono */

@font-face {
	font-family: 'PT Mono';
	src: url('../fonts/PT_Mono/PTMono-Regular.woff2')  format('woff2'),
		url('../fonts/PT_Mono/PTMono-Regular.ttf')  format('truetype');
	font-weight: 400;
	font-display: swap;
}

/* Inter */

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter/Inter-Medium.woff2')  format('woff2'),
		url('../fonts/Inter/Inter-Medium.ttf')  format('truetype');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter/Inter-Bold.woff2')  format('woff2'),
		url('../fonts/Inter/Inter-Bold.ttf')  format('truetype');
	font-weight: 700;
	font-display: swap;
}

/* Clash_Display */

@font-face {
	font-family: 'Clash Display';
	src: url('../fonts/Clash_Display/ClashDisplay-Extralight.woff2')  format('woff2'),
		url('../fonts/Clash_Display/ClashDisplay-Extralight.ttf')  format('truetype');
	font-weight: 200;
	font-display: swap;
}

@font-face {
	font-family: 'Clash Display';
	src: url('../fonts/Clash_Display/ClashDisplay-Light.woff2')  format('woff2'),
		url('../fonts/Clash_Display/ClashDisplay-Light.ttf')  format('truetype');
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'Clash Display';
	src: url('../fonts/Clash_Display/ClashDisplay-Regular.woff2')  format('woff2'),
		url('../fonts/Clash_Display/ClashDisplay-Regular.ttf')  format('truetype');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Clash Display';
	src: url('../fonts/Clash_Display/ClashDisplay-Medium.woff2')  format('woff2'),
		url('../fonts/Clash_Display/ClashDisplay-Medium.ttf')  format('truetype');
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: 'Clash Display';
	src: url('../fonts/Clash_Display/ClashDisplay-Semibold.woff2')  format('woff2'),
		url('../fonts/Clash_Display/ClashDisplay-Semibold.ttf')  format('truetype');
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'Clash Display';
	src: url('../fonts/Clash_Display/ClashDisplay-Bold.woff2')  format('woff2'),
		url('../fonts/Clash_Display/ClashDisplay-Bold.ttf')  format('truetype');
	font-weight: 700;
	font-display: swap;
}

.link{
	@apply relative;
}

.link::before{
	content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.link:hover:before {
  visibility: visible;
  width: 100%;
}