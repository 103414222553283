.checkbox {
  @apply grid gap-4 mb-4 items-center hover:cursor-pointer;
  grid-template-columns: min-content auto;
}

.checkbox-input{
  @apply relative;
}

.checkbox-input input {
  @apply opacity-0 h-6 w-6;
}

.checkbox-control{
  @apply absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 bg-secondaryC rounded border border-primaryALight;
}

.checkbox-control svg {
  @apply absolute top-1/2 left-1/2;
  transition: transform 0.1s ease-in 25ms;
  transform: scale(0) translate(-50%, -50%);
  transform-origin: bottom left;
}

.checkbox-input input:checked
 + .checkbox-control svg {
  transform: scale(1) translate(-50%, -50%);
}

.checkbox-input input:focus
 + .checkbox-control {
  box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em border-primaryA;
}

.checkbox-label{
  @apply text-base leading-6 tracking-tight;
}

.checkbox-label.disabled {
  @apply text-secondaryB;
}