.grow-wrap {
  @apply grid relative w-full;
}

.grow-wrap.in-creator {
  @apply mr-4;
}

.grow-wrap::after {
  content: attr(data-replicated-value) " ";
  @apply invisible whitespace-pre-wrap;
}
.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  @apply py-3 pl-4 pr-14 font-display text-base leading-6 tracking-tight border border-solid rounded-lg overflow-y-auto
  outline-none focus:outline-none active:outline-none hover:outline-none;
  grid-area: 1 / 1 / 2 / 2;
  max-height: 128px;
}

.grow-wrap.in-event {
  @apply bg-primaryD rounded-lg;
}

.grow-wrap.in-preview > textarea,
.grow-wrap.in-preview::after {
  @apply text-primaryC bg-secondaryC border-primaryC focus:border-primaryA active:border-primaryA hover:border-primaryA;
}

.grow-wrap.in-creator > textarea,
.grow-wrap.in-creator::after {
  @apply text-primaryC bg-secondaryC border-primaryC focus:border-primaryA active:border-primaryA hover:border-primaryA;
}

.grow-wrap.in-event > textarea,
.grow-wrap.in-event::after {
  @apply text-primaryC bg-transparent;
  @apply border-none;
}

.grow-wrap.in-event > textarea::placeholder {
  @apply text-secondaryA;
}

.grow-wrap.in-event > textarea:-ms-input-placeholder {
  @apply text-primaryD;
}

.grow-wrap.in-event > textarea::-ms-input-placeholder {
  @apply text-primaryD;
}

.grow-wrap > textarea:focus,
.grow-wrap > textarea:active {
  @apply border-primaryA;
}
.chat{
  @apply relative flex flex-col justify-between h-full;
  max-height: 880px;
}

.chat.in-preview {
  @apply bg-primaryD shadow-card rounded-lg -bottom-10 -right-10 -left-10;
}

.chat.in-creator:not(.minimizable) {
  @apply bg-primaryD shadow-card rounded-lg mt-8;
  height: calc(100vh - 3*54px);
}

.chat.in-creator.minimizable  {
  @apply bg-primaryD shadow-card rounded-lg mt-8;
}

.chat.in-event {
  @apply bg-primaryD w-full shadow-card rounded-lg;
  max-height: 100%;
}

.chat-header{
  @apply flex justify-between p-4 border-b border-secondaryB;
  height: 3.25rem;
}

.chat-header-btn{
  @apply relative hover:text-primaryA hover:outline-none active:outline-none focus:outline-none;
  transition: color 0.3s ease-in-out;
}

.chat-header-btn:hover .tooltip-chat-header{
  @apply -left-24 opacity-100 visible;
}

.tooltip-chat-header{
  @apply absolute top-0 -left-26 opacity-0 invisible;
  transition: all 0.3s ease-in-out;
}

.chat-body {
  @apply relative flex flex-col h-full py-4 px-2 overflow-auto pt-6;
  max-height: 50rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-mask-image: -webkit-gradient(linear, 100% 20%, 100% 1%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.chat-body > :first-child {
  margin-top: auto !important;
  /* use !important to prevent breakage from child margin settings */
}

.chat-body::-webkit-scrollbar {
  display: none;
}

.chat-level{
  @apply absolute -bottom-1 -left-1 rounded-full text-white font-display font-bold text-xs text-center;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.chat-level.in-preview {
  @apply bg-primaryB;
}

.chat-level.in-creator {
  @apply bg-primaryB;
}

.chat-level.in-event {
  @apply bg-primaryA;
}

.chat-level:hover .tooltip-level{
  @apply -right-14 opacity-100 visible;
}

.chat-go-bottom{
  @apply w-10 h-10 absolute transform -top-14 left-1/2 -translate-x-1/2 text-primaryA font-bold bg-primaryALight
   rounded-full shadow-card hover:outline-none active:outline-none focus:outline-none z-30;
   transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.tooltip-level{
  @apply absolute -right-16 opacity-0 invisible;
  bottom: -2px;
  transition: all 0.3s ease-in-out;
}

.chat-footer{
  @apply relative flex items-end p-4;
  height: auto;
  -webkit-transform: translate3d(0,0,1px);
  transform: translate3d(0,0,1px);
}

.chat-footer.in-preview{
  @apply border-t border-secondaryB;
}

.chat-footer.in-creator{
  @apply border-t border-secondaryB;
}

.chat-footer.in-event{
  @apply p-4;
  @apply border-t border-secondaryB;
}

.chat-message{
  @apply relative flex py-3 pl-3 pr-8 mt-4 rounded-lg;
  transition: all 0.3s ease-in-out;
}

.chat-message.in-preview:hover {
  @apply bg-grey;
}

.chat-message.in-creator:hover {
  @apply bg-grey;
}

.chat-message:hover time, .chat-message:hover .chat-dots{
  @apply opacity-100 visible;
}

.chat-message-time {
  @apply absolute right-2 top-1 font-display font-medium leading-4 tracking-tight text-xs opacity-0 invisible;
}

.chat-message-time.in-preview {
  @apply text-secondaryA;
}

.chat-message-time.in-creator {
  @apply text-secondaryA;
}

.chat-message-time.in-event {
  @apply text-secondaryA;
}

.chat-message time, .chat-message .chat-dots{
  transition: all 0.3s ease-in-out;
}

.chat-message-avatar-container {
  @apply relative -top-1 min-w-8 h-8 mr-2;
}

.chat-message-text-container {
  @apply font-display font-medium text-sm tracking-tight z-10 break-words;
  word-break: break-word;
}

.chat-message-text-container.in-preview {
  @apply text-darkGrey;
}

.chat-message-text-container.in-creator {
  @apply text-darkGrey;
}

.chat-message-text-container.in-event {
  @apply text-darkGrey;
}

.chat-message-username {
  @apply inline-block mr-2 font-bold;
}

.chat-message-username.in-preview {
  @apply text-primaryA;
}

.chat-message-username.in-creator {
  @apply text-primaryA;
}

.chat-message-username.in-event {
  @apply text-primaryA;
}

.chat-dots {
  @apply absolute right-1 bottom-1 opacity-0 invisible;
}

.emoji-btn {
  @apply absolute right-4 bottom-3 focus:outline-none hover:text-primaryA hover:outline-none active:outline-none;
}

.in-event .chat-submit-bnt {
  @apply w-12 self-end mb-3;
}

.chat-submit-bnt{
  @apply relative focus:text-primaryA active:text-primaryA hover:text-primaryA focus:outline-none;
}

.chat-submit-bnt.focus{
  @apply text-primaryA;
}

.sticker-menu{
  @apply absolute left-2 right-2 z-10 bg-grey shadow-card rounded-lg overflow-hidden;
  transition: all 0.3s ease-in-out;
}

.sticker-menu div:nth-child(2){
  overflow: auto;
  height: calc(100% - 53px);
}

.sticker-menu div:nth-child(2) {
  @apply w-full overflow-y-auto max-h-106 pt-10;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-mask-image: -webkit-gradient(linear, 100% 20%, 100% 1%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.sticker-menu div:nth-child(2)::-webkit-scrollbar {
  display: none;
}

/* width */
.grow-wrap > textarea::-webkit-scrollbar {
  @apply w-3;
}

/* Track */
.grow-wrap > textarea::-webkit-scrollbar-track {
  @apply bg-transparent w-3;
}
 
/* Handle */
.grow-wrap > textarea::-webkit-scrollbar-thumb {
  @apply rounded-lg w-3;
  background-color: #C1C1C1;
}

/* Handle on hover */
.grow-wrap > textarea::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.grow-wrap > textarea::-moz-selection{
  @apply bg-primaryA text-primaryD;
}

.grow-wrap > textarea::selection{
  @apply bg-primaryA text-primaryD;
}


/* Customize the label (the container) */
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  background-color: #fff;
  border: 1px solid #e4d5ff;
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #5200e9;
}

.creator-chat-button {
  @apply font-display text-sm text-primaryC font-bold mr-8 active:outline-none focus:outline-none;
  @apply hover:text-primaryADark;
}

.creator-chat-button.active {
  @apply text-primaryA
}

.participant-container {
  @apply flex items-center mx-4 my-2 cursor-default;
}

.participant-displayName {
  @apply font-display text-sm text-primaryC font-bold;
}

.participants-container, .polls-container {
  @apply flex flex-1 flex-col mt-4 overflow-hidden hover:overflow-y-auto;
}

.participants-container::-webkit-scrollbar-track,
.polls-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.participants-container::-webkit-scrollbar,
.polls-container::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.participants-container::-webkit-scrollbar-thumb,
.polls-container::-webkit-scrollbar-thumb
{
	background-color: #6B7280;
	border: 2px solid #6B7280;
}

.polls-container {
  @apply px-4 relative;
}

.new-poll-card {
  @apply absolute top-0 left-4 right-4 bg-white z-20 shadow-card flex items-center justify-center flex-col py-8 rounded-lg;
}

.user-poll-indication {
  @apply flex justify-between;
  @apply bg-primaryA p-4;
  @apply cursor-pointer shadow-card;
  @apply absolute bottom-0 left-0 right-0;
}

.user-poll-container {
  @apply p-4 pt-8 bg-grey shadow-card rounded-lg overflow-hidden;
  @apply absolute bottom-4 left-4 right-4;
  transition: all 0.3s ease-in-out;
}

.user-poll-close-button {
  @apply flex items-center justify-center;
  @apply w-6 h-6;
  @apply absolute top-2 right-2;
  @apply focus:outline-none;
}

.minimized-chat-mock-container {
  @apply flex flex-col;
  @apply shadow-card rounded-lg;
  width: 327px;
}

.chat-mock-heading {
  @apply flex items-center justify-between p-4;
  @apply border-b border-secondaryB;
}

.input-mock-container {
  @apply h-12 flex flex-1 px-4 py-0 m-4 mb-0;
  @apply border border-primaryC rounded-lg;
}

.input-mock-container input {
  @apply w-full;
}

.chat-mock-bottom {
  @apply flex items-center justify-between p-4;
}

.chat-mock-button {
  @apply h-12 w-12 flex items-center justify-center relative;
  @apply hover:text-primaryA;
}

.chat-mock-button .count-badge {
  @apply w-4 h-4 bg-primaryB rounded-full;
  @apply absolute top-1 right-1;
  @apply font-bold font-display text-primaryD;
  font-size: 0.625rem;
}

.chat-mock-join-button {
  @apply w-6 h-6 flex items-center justify-center;
  @apply border-2 border-primaryC rounded-full;
  @apply hover:text-primaryA hover:border-primaryA;
}

.join-button {
  @apply font-display font-medium text-sm text-primaryC focus:outline-none;
}

.donations-menu {
  @apply absolute left-2 right-2 z-10 bg-grey shadow-card rounded-lg overflow-hidden;
  transition: all 0.3s ease-in-out;
}

.donations-menu-header {
  @apply relative flex flex-col items-center justify-center;
  height: 78px;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.donations-menu-body {
  @apply flex flex-col px-10 py-3;
}

.donations-menu-input-container {
  @apply flex border-t border-secondaryB py-5;
}

.donations-menu-input {
  @apply flex w-32 bg-white border-secondaryB rounded-lg px-3 py-2 mr-4;
}

.donations-menu-input-button {
  @apply bg-primaryC rounded-lg;
  @apply text-sm font-display text-white;
  @apply px-4 px-2;
}

.sticky-comment-container {
  @apply bg-primaryD shadow-card rounded-lg m-2 p-4;
}

.sticky-comment-container .sticky-label {
  @apply text-xs font-display text-secondaryA mb-1;
}

.sticky-comment-container .sticky-comment {
  @apply font-display font-bold text-base text-primaryC;
}

.sticky-comment-container .comment-by {
  @apply font-display text-xs leading-4 text-secondaryA;
}

.sticky-comment-container .comment-by .user {
  @apply text-primaryA;
}