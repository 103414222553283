.slider {
  @apply w-full h-full relative overflow-hidden rounded-lg;
  background: black;
}

.slide {
  @apply absolute inset-0;
  transition: all 0.5s;
}

.slide img {
  @apply w-full h-full;
  object-fit: contain;
}

.slider-btn {
  @apply z-10 cursor-pointer flex items-center justify-center;
  @apply absolute w-16 h-16;
  @apply focus:outline-none;
  top: 45%;

  opacity: 0;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
}
.slider-btn.visible {
  opacity: 1.0;
  transition: opacity .55s ease-in-out;
  -moz-transition: opacity .55s ease-in-out;
  -webkit-transition: opacity .55s ease-in-out;
}

.slider-btn:active {
  transform: scale(1.1);
}
.slider-btn.prev {
  @apply left-0;
}

.slider-btn.next {
  @apply right-0;
}

.wrap {
  @apply flex w-full px-2;
}

.range {
  -webkit-appearance: none;
  cursor: pointer;
  height: 3px;
  margin: 0;
  transition: 0.1s ease-in;
  vertical-align: bottom;
  width: 100%;
}
.wrap:hover .range,
.wrap.hover .range { height: 8px; }

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #aeaeae;
  border-radius: 8px;
  box-shadow: inset 0 0 0 5px #eaeaea;
  height: 0;
  transition: 0.1s ease-in;
  width: 0;
}
.wrap:hover .range::-webkit-slider-thumb,
.wrap.hover .range::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
}

.loaded .range,
.loaded .range::-webkit-slider-thumb {
  transition: 0.1s ease-in;
}

:focus {
  outline: none;
}