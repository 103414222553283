.system-banner{
  @apply relative flex items-center justify-center min-h-5 px-4 py-2 mb-2 z-50;
}

.system-banner.primary{
  @apply bg-primaryADark text-primaryD;
}

.system-banner.warning{
  @apply bg-warningLight text-primaryC;
}

.system-banner.danger{
  @apply bg-primaryBlight text-primaryBDark;
}

.system-banner.success{
  @apply bg-positiveLight text-positive;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}