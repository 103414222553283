.drawer{
  @apply h-full fixed top-0 transform transition duration-300 ease-in-out bg-primaryD z-50;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
}

.close-drawer{
  @apply text-primaryA hover:text-primaryADark;
}

.drawer.event{
  @apply w-100 p-10;
  background: linear-gradient(193deg, rgba(80,0,233,0.19) 0%, rgba(0,2,161,0.45) 33%, rgba(70,0,77,0.74) 66%, rgba(0,0,0,1) 100%), linear-gradient(193deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%);
  backdrop-filter: blur(20px);
}

.drawer.user, .drawer.my-goods {
  @apply  w-82 p-4;
}

.drawer.user > div{
  @apply top-1/2 -translate-y-1/2;
}

.drawer.event .drawer-video, .drawer.event .drawer-slider {
  @apply transition duration-300 ease-in-out;
}

.event-preview-video video {
  @apply object-cover object-center;
}

.video-visible {
  @apply opacity-100 visible h-auto;
}

.video-invisible {
  @apply opacity-0 invisible h-0;
}

.drawer.event .drawer-slider{
  @apply delay-700;
}

.event-ended-preview-contestorA-container {
  @apply text-left flex flex-1 flex-col mr-2 min-w-0;
}

.event-ended-preview-contestorB-container {
  @apply text-right flex flex-1 flex-col ml-2 min-w-0;
}

.event-ended-preview-contestorA-container .event-contestor-name,
.event-ended-preview-contestorB-container .event-contestor-name {
  @apply text-base font-normal;
  max-width: 168px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-ended-preview-contestorA-container .contestor-color.blue {
  @apply ml-0 mr-2 w-5 h-5;
}

.event-ended-preview-contestorB-container .contestor-color.red {
  @apply mr-0 ml-2 w-5 h-5;
}

.event-ended-preview-score {
  
}

.event-ended-preview-score .number {
  @apply font-display font-bold text-xl
}

.event-ended-preview-score .text {
  @apply font-display font-normal text-base;
}