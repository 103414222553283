.room-banner-container {
  @apply bg-secondaryB -mx-8;
  height: 306px;
}

.room-title-container {
  @apply flex items-center justify-start mt-8 mb-4 gap-4;
}

.room-image {
  @apply w-16 h-16 rounded-full;
}

.room-navigation-container {
  @apply border-b border-secondaryB -mx-8 px-8 pb-8 pt-4 sticky top-navbarHeight bg-white z-10;
}

.room-navigation-button {
  @apply text-base leading-6 text-primaryC font-display hover:text-primaryA focus:outline-none;
}

.room-navigation-button.active {
  @apply text-primaryA;
}

/* TODO: Refactor */
.room-share-position {
  z-index: 100 !important;
  right: 0;
  margin-top: 12px;
  left: auto;
}