.goods-menu{
  @apply absolute left-2 right-2 z-10 bg-grey shadow-card rounded-lg overflow-hidden;
  transition: all 0.3s ease-in-out;
}

.goods-menu-content-container {
  @apply p-4 h-full overflow-y-auto mr-1;
  overflow: auto;
  height: calc(100% - 53px);
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-mask-image: -webkit-gradient(linear, 100% 20%, 100% 1%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.goods-menu-content-container::-webkit-scrollbar {
  display: none;
}

.goods-menu-items-container {
  @apply flex flex-wrap items-start mb-6;
}

.goods-menu-label {
  @apply font-display font-semibold text-sm tracking-tight text-primaryC mb-5;
}

.goods-menu-item-container {
  @apply flex flex-col items-center relative;
  @apply mr-4 mb-2;
}

.goods-menu-item-amount {
  @apply w-4 h-4 rounded-full absolute -top-1 -right-1 flex items-center justify-center;
  @apply bg-primaryB text-primaryD text-xs;
}

.goods-menu-item-image-container {
  @apply w-8 h-8 bg-primaryA rounded-full flex items-center justify-center shadow-card;
}

.goods-menu-item-label {
  @apply text-xs;
}