.image-upload-container {
  @apply w-full h-0 relative;
  padding-top: 56.25%;
}

.image-upload-content-container {
  @apply absolute inset-0 flex;
  @apply flex-1 flex-col items-center justify-center;
  @apply bg-secondaryC rounded-lg cursor-pointer;
  @apply border border-transparent hover:border hover:border-secondaryB
}

.image-upload-title {
  @apply font-display text-base text-primaryADark;
}

.image-upload-subtitle {
  @apply font-display text-base text-secondaryB;
}

.image-upload-image {
  @apply rounded-lg;
}