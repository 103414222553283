.game-item-container {
  @apply relative rounded-full cursor-pointer;
  background-color: rgba(0, 0, 0, 0.09);
  /* z-index: 31; */
  -webkit-transform: translate3d(0,0,20px);
  transform: translate3d(0,0,20px);
  pointer-events: all;
}

.game-item-container img {
  @apply w-10 h-10 absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
}

.game-item-container.empty img,
.game-item-container.delay img {
  filter: grayscale(100%);
  opacity: 0.50;
}

img.gamification-img {
  @apply w-3/4 h-auto;
}

.btn-amount {
  @apply absolute right-0 w-6 h-6 bg-primaryB rounded-full text-primaryD text-sm text-center tracking-tighter font-bold leading-6 font-display hover:cursor-default;
  top: -5px;
}

.circle {
  position: absolute;
  background: transparent;
  border: 2px solid;
  margin: auto;
  border-radius: 100%;
  overflow: hidden;
  opacity: 0
}

.circle.animated {
  animation: grow 1.5s ease;
}

@keyframes grow {
  0% {
    opacity: 0;
    -webkit-transform: scale( 1 );
    -moz-transform: scale( 1 );
    -o-transform: scale( 1 );
    -ms-transform: scale( 1 );
    transform: scale( 1 );
  }

  50% {
    opacity: 1;
    -webkit-transform: scale( 1.2 );
    -moz-transform: scale( 1.2 );
    -o-transform: scale( 1.2 );
    -ms-transform: scale( 1.2 );
    transform: scale( 1.2 );
  }

  100% {
    opacity: 0;
    -webkit-transform: scale( 1.5 );
    -moz-transform: scale( 1.5 );
    -o-transform: scale( 1.5 );
    -ms-transform: scale( 1.5 );
    transform: scale( 1.5 );
  }
}

.clap-button {
  @apply bg-white rounded-full
}
