.navbar-container {
  @apply h-navbar border-b border-secondaryB px-4 fixed left-0 right-0 bg-white;
  z-index: 20;
}

.navbar-links-container {
  @apply items-center flex flex-1;
}

.navbar-link {
  @apply font-display text-sm text-primaryC font-bold mr-8;
  @apply hover:text-primaryADark;
}

.navbar-link.active {
  @apply text-primaryA
}

.navbar-get-more-link {
  @apply font-display text-sm text-primaryC font-bold mr-8 underline;
  @apply hover:text-primaryADark;
}

.navbar-coins {
  @apply font-display text-sm leading-5 text-primaryC;
}

.navbar-user-displayName {
  @apply font-display text-sm text-primaryC font-bold;
}