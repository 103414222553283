.avatar{
  @apply rounded-full object-cover;
}

.avatar-size-1{
  @apply w-30 h-30;
}

.avatar-size-2{
  @apply w-26 h-26;
}

.avatar-size-3{
  @apply w-24 h-24;
}

.avatar-size-4{
  @apply w-18 h-18;
}

.avatar-size-4-5{
  @apply w-16 h-16;
}

.avatar-size-5{
  @apply w-10 h-10;
}

.avatar-size-6{
  @apply w-8 h-8;
}

.avatar-size-7{
  @apply w-6 h-6;
}

.avatar-animation{
  @apply shadow-card rounded-full;
}

.round-bt-container{
  @apply relative active:outline-none hover:outline-none focus:outline-none;
}
.round-bt{
  @apply relative rounded-full;
  box-shadow: 2px 1px 15px -9px #000000;
}
.round-bt-container:hover .avatar-ring{
  border:2px solid rgba(82,0,233,0.8);
  transform: scale(1.3);
}

.avatar-ring{
  @apply absolute rounded-full top-0 right-0 bottom-0 left-0 w-full h-full;
  border: 0px solid #eeeeee;
  transition:all 0.2s ease;
}