@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/typography.css';
@import './styles/buttons.css';
@import './styles/input.css';
@import './styles/checkbox.css';
@import './styles/welcome-page.css';
@import './styles/drawer.css';
@import './styles/avatar.css';
@import './styles/progress-ring.css';
@import './styles/navbar.css';
@import './styles/helpers.css';
@import './styles/dropdown.css';
@import './styles/dashboard-events-filter.css';
@import './styles/badge.css';
@import './styles/table.css';
@import './styles/account-tab-menu.css';
@import './styles/progress-bar.css';
@import './styles/spinner.css';
@import './styles/modal.css';
@import './styles/system-banner.css';
/* @import './styles/clap-bar.css'; */
@import './styles/badge-progress-bar.css';
@import './styles/tooltip.css';
@import './styles/chat.css';
@import './styles/empty-game-item.css';
@import './styles/account.css';
@import './styles/event-page.css';
@import './styles/game-item.css';
@import './styles/not-found-page.css';
@import './styles/event-leaderboard.css';
@import './styles/mobile.css';
@import './styles/events-admin.css';
@import './styles/notifications.css';
@import './styles/creator.css';
@import './styles/event.css';
@import './styles/room-tile.css';
@import './styles/media-slider.css';
@import './styles/my-goods-menu.css';
@import './styles/promotions.css';
@import './styles/room.css';
@import './styles/image-upload.css';
@import './styles/video-upload.css';
@import './styles/subscription.css';