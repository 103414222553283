.event-container {
  @apply flex overflow-hidden;
  border: 1px solid green;
}

.event-chat-wrapper {
  @apply w-80 h-full flex items-end;
}

.aspect-ratio-box {
  @apply relative;
}

.aspect-ratio-box video {
  @apply rounded-lg;
}

.event-details-container {
  @apply pt-4;
}

.event-page-container {
  @apply flex flex-1 justify-center overflow-y-auto overflow-x-hidden;
  /* height: calc(100vh - 56px); */
}

.share-position {
  top: -256px;
  left: 74px;
}

.event-date-time {
  @apply font-display font-medium text-xl leading-4.5 tracking-tight text-primaryC;
  @apply inline-block mb-7;
}

.event-description {
  @apply font-display text-base text-primaryC;
  @apply mb-8;
}

.teaser-controls-container {
  @apply h-12 flex items-center absolute bottom-0 left-0 right-0 px-4;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
}

.teaser-controls-container.visible {
  opacity: 1.0;
  transition: opacity .55s ease-in-out;
  -moz-transition: opacity .55s ease-in-out;
  -webkit-transition: opacity .55s ease-in-out;
}

.teaser-controls-btn {
  @apply w-12 h-12 flex items-center justify-center text-white focus:outline-none;
}

.event-goods-btn {
  @apply flex items-center justify-center relative focus:outline-none;
}

.event-goods-btn span {
  @apply bg-primaryB w-4 h-4 rounded-full flex items-center justify-center;
  @apply font-bold font-display text-primaryD;
  @apply absolute -top-2 -right-1;
  font-size: 0.625rem;
}

.event-player-controls-container {
  @apply absolute bottom-0 left-0 right-0 h-14 flex items-center justify-between;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
}

.event-player-controls-container.visible {
  @apply absolute bottom-0 left-0 right-0 h-14 flex items-center justify-between;
  opacity: 1.0;
  transition: opacity .55s ease-in-out;
  -moz-transition: opacity .55s ease-in-out;
  -webkit-transition: opacity .55s ease-in-out;
}

.event-video-mask-bottom {
  @apply absolute inset-0 rounded-lg;
  background: linear-gradient(181deg, rgba(0,0,0,0) 60%, rgba(0,0,0,0.7) 84%);
  opacity: 0;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
}

.event-video-mask-bottom.visible {
  opacity: 1.0;
  transition: opacity .55s ease-in-out;
  -moz-transition: opacity .55s ease-in-out;
  -webkit-transition: opacity .55s ease-in-out;
}

.event-player-controls-button {
  @apply w-12 h-12 flex items-center justify-center focus:outline-none;
}

.event-video video {
  @apply rounded-lg;
}

.event-score-container {
  @apply font-display font-bold text-xl text-white leading-8;
  @apply flex relative;
}

.event-current-round {
  @apply font-display font-semibold text-sm rounded-full bg-white text-primaryC w-8 h-8 flex justify-center items-center mx-3;
}

.clap-booster-container {
  @apply w-12 mr-4 flex flex-col items-center justify-center;
}

.gamification-container {
  @apply absolute bottom-6 inset-x-0 flex items-end;
  pointer-events: none;
}

.ended-event-tabs {
  @apply absolute -bottom-4 left-0 right-0 h-2 flex items-center justify-center;
}

.ended-event-tab {
  @apply w-2/12 h-full bg-primaryALight hover:bg-primaryA rounded-lg;
  @apply focus:outline-none;
}

.ended-event-tab.active {
  @apply bg-primaryA;
}

.event-ended-contestorA-container {
  @apply flex flex-col text-left mr-4;
}

.event-ended-contestorB-container {
  @apply flex flex-col text-left ml-4;
}

.event-ended-contestorA-container .contestor-color.blue {
  @apply ml-0 mr-2;
}

.event-ended-contestorB-container .contestor-color.red {
  @apply mr-0 ml-2;
}

.event-ended-contestorB-container {
  @apply flex flex-col text-right;
}

.event-ended-whitespace {
  @apply flex flex-1;
}

.event-ended-contestors {
  @apply flex flex-nowrap justify-between;
}

.event-contestor-name {
  @apply font-display font-medium text-white text-base;
}

.contestor-color.red {
  background: -webkit-radial-gradient(55% 2%, circle, white 1px, white 1%, #c4003d 60%, #c4003d 100%); 
  background: -moz-radial-gradient(55% 2%, circle, white 1px, white 1%, #c4003d 60%, #c4003d 100%); 
  background: -o-radial-gradient(55% 2%, circle, white 1px, white 1%, #c4003d 60%, #c4003d 100%);
  background: radial-gradient(circle at 55% 2%, white 1px, white 1%, #c4003d 60%, #c4003d 100%);
  @apply w-4 h-4 rounded-full mr-2;
}

.contestor-color.blue {
  background: -webkit-radial-gradient(55% 15%, circle, white 1px, aqua 3%, #5200e9 60%, #5200e9 100%); 
  background: -moz-radial-gradient(55% 15%, circle, white 1px, aqua 3%, #5200e9 60%, #5200e9 100%); 
  background: -o-radial-gradient(55% 15%, circle, white 1px, aqua 3%, #5200e9 60%, #5200e9 100%);
  background: radial-gradient(circle at 55% 15%, white 1px, aqua 3%, #5200e9 60%, #5200e9 100%);
  @apply w-4 h-4 rounded-full ml-2;
}

.event-page-container {
  scrollbar-width: thin;
}

.event-page-container::-webkit-scrollbar {
	width: 9px;
	background-color: #F5F5F5;
}

.event-page-container::-webkit-scrollbar-thumb {
	background-color: #b3b3b3;
	border: 2px solid #b3b3b3;
  border-radius: 9px;
}

.event-page-container::-webkit-scrollbar-thumb:hover {
	background-color: #dadada;
	border: 2px solid #dadada;
  border-radius: 10px;
}