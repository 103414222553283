.loader,
.loader:after {
  @apply rounded-full;
}

.loader.big,
.loader.big:after {
  @apply w-22 h-22;
}

.loader.medium,
.loader.medium:after {
  @apply w-14 h-14;
}

.loader.small,
.loader.small:after {
  @apply w-9 h-9;
}

.loader.extra-small,
.loader.extra-small:after {
  @apply w-5 h-5;
}

.loader.big{
  border-top: 9px solid #5200e9;
  border-right: 9px solid #dadada;
  border-bottom: 9px solid #dadada;
  border-left: 9px solid #dadada;
}

.loader.medium{
  border-top: 6px solid #5200e9;
  border-right: 6px solid #dadada;
  border-bottom: 6px solid #dadada;
  border-left: 6px solid #dadada;
}

.loader.small{
  border-top: 4px solid #5200e9;
  border-right: 4px solid #dadada;
  border-bottom: 4px solid #dadada;
  border-left: 4px solid #dadada;
}

.loader.extra-small{
  border-top: 2px solid #5200e9;
  border-right: 2px solid #dadada;
  border-bottom: 2px solid #dadada;
  border-left: 2px solid #dadada;
}

.loader {
  @apply relative m-auto; 
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}