.event-video-container {
  @apply col-span-12 rounded-lg relative mb-4 overflow-hidden;
  max-height: calc(100vh - (54px + 54px + 48px));
  aspect-ratio: 16/9;
}

.event-video-container video {
  @apply rounded-lg;
}

.event-video-mask-top {
  @apply absolute inset-0 rounded-lg;
  background: linear-gradient(360deg, rgba(0,0,0,0) 70%, rgba(0,0,0,0.7) 84%);
}

/* .event-video-mask-bottom {
  @apply absolute inset-0 rounded-lg;
  background: linear-gradient(181deg, rgba(0,0,0,0) 60%, rgba(0,0,0,0.7) 84%);
} */

.event-stats-container {
  @apply absolute top-4 inset-x-0 text-center;
  /* z-index: 31; */
  z-index: 1;
}

/* .event-score-container {
  @apply font-display font-bold text-3xl text-white;
  @apply flex relative;
} */

.participant-leave-btn {
  @apply absolute left-4 p-3 focus:outline-none text-white ;
}

.event-score-container span {
  @apply font-display font-bold text-2xl text-white;
}

/* .event-contestor-name {
  @apply font-display font-bold text-white text-xl;
} */

.event-contestors-container {
  @apply flex items-center justify-center mt-3;
}

/* .event-current-round {
  @apply font-display font-bold text-3xl rounded-full bg-white text-primaryC w-14 h-14 flex justify-center items-center mx-3;
} */

/* .contestor-color.red {
  background: -webkit-radial-gradient(55% 2%, circle, white 1px, white 1%, #c4003d 60%, #c4003d 100%); 
  background: -moz-radial-gradient(55% 2%, circle, white 1px, white 1%, #c4003d 60%, #c4003d 100%); 
  background: -o-radial-gradient(55% 2%, circle, white 1px, white 1%, #c4003d 60%, #c4003d 100%);
  background: radial-gradient(circle at 55% 2%, white 1px, white 1%, #c4003d 60%, #c4003d 100%);
  @apply w-6 h-6 rounded-full mr-2;
}

.contestor-color.blue {
  background: -webkit-radial-gradient(55% 15%, circle, white 1px, aqua 3%, #5200e9 60%, #5200e9 100%); 
  background: -moz-radial-gradient(55% 15%, circle, white 1px, aqua 3%, #5200e9 60%, #5200e9 100%); 
  background: -o-radial-gradient(55% 15%, circle, white 1px, aqua 3%, #5200e9 60%, #5200e9 100%);
  background: radial-gradient(circle at 55% 15%, white 1px, aqua 3%, #5200e9 60%, #5200e9 100%);
  @apply w-6 h-6 rounded-full ml-2;
} */

/* .gamification-container {
  @apply absolute bottom-6 inset-x-0 flex items-end;
} */

.event-chat-container {
  @apply flex flex-1 items-end mx-4 h-full ;
}

.event-contestor-actions {
  @apply flex-1 items-end;
}

.event-contestor-actions.contestorA {
  @apply flex justify-end;
}

.event-contestor-actions.contestorB {
  @apply flex justify-start;
}

.toggle-chat-button {
  @apply absolute bottom-4 right-4 p-3 focus:outline-none;
}

.toggle-mute-button {
  @apply absolute bottom-4 left-4 p-3 focus:outline-none text-white ;
}

.toggle-pip-button {
  @apply absolute bottom-3 right-16 p-3 focus:outline-none text-white ;
}

.toggle-fullscreen-button {
  @apply absolute bottom-4 right-4 p-3 focus:outline-none text-white ;
}

.toggle-event-fullscreen-button {
  @apply absolute bottom-4 left-16 p-3 focus:outline-none text-white ;
}

.event-ended-score-container {
  @apply absolute top-24 inset-x-0 text-center flex flex-1;
}

/* .event-ended-contestorA-container {
  @apply flex flex-col text-left mr-4;
}

.event-ended-contestorB-container {
  @apply flex flex-col text-left ml-4;
}

.event-ended-contestorA-container .contestor-color.blue {
  @apply ml-0 mr-2;
}

.event-ended-contestorB-container .contestor-color.red {
  @apply mr-0 ml-2;
}

.event-ended-contestorB-container {
  @apply flex flex-col text-right;
}

.event-ended-whitespace {
  @apply flex flex-1;
}

.event-ended-contestors {
  @apply flex flex-1 flex-nowrap justify-between;
} */