.btn-img-holder {
  @apply absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rounded-full;
}

.btn-img-holder.blue {
  background-image: radial-gradient(circle at 54% 50%, #a562f5, #2500dc 96%);
  transition: all 0.3s ease-in-out;
}

.btn-img-holder.white {
  background-image: radial-gradient(circle at 54% 50%, #fff, #fff 96%);
}

.btn-img-holder.blue.active{
  background-image: radial-gradient(circle at 54% 50%, #a562f5, #2500dc 96%);
}

.btn-img-holder.white.active {
  background-image: radial-gradient(circle at 54% 50%, #fff, #fff 96%);
}

.btn-img-holder:hover .item-tooltip{
  @apply right-16 opacity-100 visible;
}

.item-tooltip{
  @apply absolute right-20 transform top-1/2 -translate-y-1/2 min-w-10 opacity-0 invisible;
  transition: all 0.3s ease-in-out;
}

.btn-img-holder.empty{
  @apply bg-primaryD;
  background-image: none;
}

.btn-img-holder.empty img{
  filter: grayscale(100%);
  opacity: 0.50;
}

.btn-img-holder img{
  @apply w-10 h-10 relative transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
}

.buy-btn{
  @apply absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-11 m-0 p-0 text-primaryA hover:outline-none active:outline-none focus:outline-none;
}

.buy-btn:hover .buy-tooltip{
  @apply left-8 opacity-100 visible;
}

.buy-tooltip {
  @apply absolute left-10 transform top-1/2 -translate-y-1/2 min-w-10 opacity-0 invisible;
  transition: all 0.3s ease-in-out;
}

.game-item-name {
  @apply font-display font-medium text-sm text-secondaryA text-center;
}
