.room-tile-container {
  @apply flex items-center justify-start;
  @apply border border-secondaryB rounded-lg;
  @apply px-7 py-8;
  height: 124px;
  width: 389px;
}

.room-tile-follow-button {
  @apply flex items-center;
  @apply text-base text-primaryA font-display font-medium focus:outline-none;
}