.tooltip{
  @apply absolute px-2 font-display font-medium text-sm leading-5 tracking-tighter text-primaryD bg-primaryA rounded;
}

.tooltip::before{
  content: '';
  position: absolute;
  top:0;
  width: 0;
  height: 0;
  border-style: solid;
}

.tooltip.left-arrow::before{
  left: -7px;
  border-width: 10px 9px 10px 0;
  border-color: transparent #5200e9 transparent transparent;
}

.tooltip.right-arrow::before{
  right: -8px;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #5200e9;
}

.react-tooltip{
  padding: 1px 8px !important;
  border-radius: 4px !important;
  background-color: #5200e9 !important;
  color: #fff !important;
  font-family:  'Inter' !important;
  font-style: 14px !important;
  line-height: 20px !important;
  letter-spacing: -0.35px !important;
}

.react-tooltip::after{
  border-right-color: #5200e9 !important;
}

.active-booster-tooltip {
    color: white;
    position: absolute;
    left: 92px;
    top: 50%;
    white-space: nowrap;
    transform: translateY(-50%);
    background-color: #5200e9;
    padding: 1px 8px;
    font-family:  'Inter';
    font-style: 14px;
    line-height: 20px;
    letter-spacing: -0.35px;
    border-radius: 4px;
    z-index: 10;
    display: none;
}

.active-booster-tooltip.visible {
  display: block;
}