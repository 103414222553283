.progress-bar progress{
  @apply w-full h-3;
}

.progress-bar progress::-moz-progress-bar{
  @apply bg-limeGreen;
}

.progress-bar progress::-webkit-progress-bar{
  @apply bg-secondaryB;
}

.progress-bar progress::-webkit-progress-value{
  @apply bg-limeGreen;
}

.progress-bar label{
  @apply relative font-display text-xs leading-4 tracking-tighter text-darkGrey;
  top:-6px;
}