.mobile, .tablet {
  display: none !important;
}

.legal {
  @apply col-start-4 col-end-10 pt-20;
}

/* ----------- Galaxy Fold ----------- */
@media
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (   min--moz-device-pixel-ratio: 3),
  only screen and (     -o-min-device-pixel-ratio: 3/1),
  only screen and (        min-device-pixel-ratio: 3),
  only screen and (                min-resolution: 384dpi),
  only screen and (                min-resolution: 3dppx) { 
    .mobile {
      display: flex !important;
    }

    .desktop, .tablet {
      display: none !important;
    }

    .legal {
      @apply col-span-12 pt-20;
    }
}
/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .mobile {
      display: flex !important;
    }

    .desktop, .tablet {
      display: none !important;
    }

    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }

    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
    .mobile {
      display: flex !important;
    }
    .desktop, .tablet {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* ----------- Tablets ----------- */

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1112px) 
  and (max-device-width: 1112px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .tablet {
      display: flex !important;
    }
    .desktop, .mobile {
      display: none !important;
    }
    .legal {
      @apply col-span-12 pt-20;
    }
}