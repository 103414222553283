.subscription-image-picker {
  .select-image,
  .empty-placeholder.landscape {
    width: 100%;
    min-height: 260px;
    max-width: 520px;
  }

  .select-image img.landscape {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}