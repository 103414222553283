.dropdown-list{
  @apply absolute z-30 right-0 mt-2 mb-0 p-0 shadow-card bg-white min-w-48 rounded-lg overflow-hidden;
}

.dropdown-item{
  @apply m-0 px-4 py-5 font-display text-base leading-6 tracking-tight text-primaryC 
  transition duration-300 ease-in-out
  hover:bg-primaryA hover:text-primaryD hover:cursor-pointer;
}

.dropdown-item::first-letter {
 @apply uppercase;
}

.dropdown-list {
  scrollbar-width: thin;
}

.dropdown-list::-webkit-scrollbar
{
	width: 9px;
	background-color: #F5F5F5;
}

.dropdown-list::-webkit-scrollbar-thumb
{
	background-color: #b3b3b3;
	border: 2px solid #b3b3b3;
  border-radius: 9px;
}

.dropdown-list::-webkit-scrollbar-thumb:hover
{
	background-color: #dadada;
	border: 2px solid #dadada;
  border-radius: 10px;
}