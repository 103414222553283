.event-leaderboard-container {
  @apply w-full overflow-y-auto max-h-full pt-10;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-mask-image: -webkit-gradient(linear, 100% 20%, 100% 1%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.event-leaderboard-container::-webkit-scrollbar {
  display: none;
}

.event-leaderboard-container table {
  @apply text-white text-left w-full;
}

.event-leaderboard-container table thead {
  @apply font-display text-base font-bold whitespace-nowrap;
}

.event-leaderboard-container th {
  @apply pr-14 py-2
}

.event-leaderboard-container tbody tr {
  border: 1px solid white;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  background-color: rgba(255,255,255,0.1);
}

.event-leaderboard-container tbody tr:last-child {
  border-bottom-width: 0;
}

.event-leaderboard-container tbody tr:hover {
  background-color: rgba(255,255,255,0.4);
}

.event-leaderboard-container th:last-of-type {
  @apply pr-0 text-right;
}

.event-leaderboard-container td:last-of-type {
  @apply pr-0 text-right;
}

.event-leaderboard-container td:first-child {
  @apply pl-4;
}

.event-leaderboard-container td:last-child {
  @apply pr-4;
}

.event-leaderboard-user {
  @apply flex py-2 px-4 items-center;
}

.event-leaderboard-username {
  @apply font-display text-xs ml-2;
}

.event-ended-score .number {
  @apply font-display font-bold text-3xl text-white;
}

.event-ended-score .text {
  @apply font-display text-xl text-white;
}