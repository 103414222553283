.events-filter{
  @apply fixed right-0 z-19 flex justify-between items-center py-6 px-8 bg-primaryD;
}

.events-filter__btn{
  @apply flex items-center mr-8 font-display text-base text-primaryC leading-6 tracking-tight hover:text-primaryA focus:outline-none;
}

.events-filter__btn.active { 
  @apply text-primaryA;
}

.events-filter-all-btn{
  @apply flex items-center ml-auto font-display font-medium text-base text-primaryA h-5 focus:outline-none;
}


.events-filter__flex{
  @apply flex items-center;
}

.forword {
  width: 9.2px;
  height: 15px;
  margin: 4px 0 1px 18px;
  object-fit: contain;
  transform: rotate(-270deg);
}