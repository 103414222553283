@keyframes switch-bg {
  0% {
    background-image: url('assets/landingPageBgs/1.webp');
  }
  20% {
    background-image: url('assets/landingPageBgs/1.webp');
  }
  25% {
    background-image: url('assets/landingPageBgs/2.webp');
  }
  45% {
    background-image: url('assets/landingPageBgs/2.webp');
  }
  50% {
    background-image: url('assets/landingPageBgs/3.webp');
  }
  70% {
    background-image: url('assets/landingPageBgs/3.webp');
  }
  75% {
    background-image: url('assets/landingPageBgs/4.webp');
  }
  95% {
    background-image: url('assets/landingPageBgs/4.webp');
  }
  100% {
    background-image: url('assets/landingPageBgs/1.webp');
  }
}

.landing-page {
  background-size: 100vw 100vh;
  animation-name: switch-bg;
  animation-duration: 32s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-timing-function: linear;
}

.landing-page::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: linear-gradient(rgb(0 0 0 / 0.4), rgb(0 0 0 / 0.4));
}

.landing-page .inner {
  max-width: 448px;
  max-height: 545px;
}

.landing-page .sign-up-btn {
  background: linear-gradient(93deg, #5200E9 5.71%, #FF004F 127.1%);
}
