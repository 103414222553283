:root {
  --primaryALight: #e4d5ff;
  --primaryA: #5200e9;
}

.mode-tab {
  @apply flex mt-4 hidden;
}

.mode-tab.selected {
  @apply flex flex-1 border-transparent p-0 pt-2;
}

.mode-tab.not-selected {
  @apply flex flex-1 border-primaryALight p-2 cursor-pointer;
}

.mode-tab.not-selected:hover input[type=radio] {
  @apply text-primaryA;
}

.mode-tab.not-selected:hover input[type=radio]:checked::before {
  box-shadow: inset 1em 1em var(--primaryA);
}

.mode-tab.selected input[type=radio] {
  @apply text-primaryA border-primaryA;
}

.mode-tab.selected input[type=radio]:checked::before {
  box-shadow: inset 1em 1em var(--primaryA);
}

.mode-tab.selected label {
  @apply font-bold;
}

.mode-tab.not-selected:hover label {
  @apply font-bold;
}

.mode-tab.not-selected .mode-tab-content {
  @apply opacity-50;
}

.mode-tab.not-selected:hover .mode-tab-content {
  @apply opacity-100;
}

.mode-tab-content .inactive {
  @apply pointer-events-none;
}

.participant-tile {
  @apply bg-transparent !important;
}

.participant-tile video {
  @apply relative shadow-card rounded-lg object-contain w-auto h-auto m-auto !important;
}

.participant-tile header {
  @apply bottom-8 !important;
}