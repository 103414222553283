.events-admin-bar {
  @apply fixed right-0 left-0 z-19 flex flex-col justify-between items-center py-6 pl-4 pr-4 border-b border-secondaryB bg-primaryD;
}

.admin-bar-bottom {
  @apply flex w-full mt-4 justify-end;
}

.events-admin-bar__flex{
  @apply flex flex-1 w-full items-center justify-between;
}

.events-admin-tabbar {
  @apply fixed right-0 left-0 z-19 flex justify-between items-center py-6 pl-4 pr-4 border-b border-secondaryB bg-primaryD;
}

.events-admin-tabbar__flex{
  @apply flex flex-1 items-center;
}

.events-tab-menu__item{
  @apply font-display text-base mr-8 tracking-tight transition duration-300 ease-in-out hover:text-primaryA active:text-primaryA focus:text-primaryA focus:outline-none;
}
.events-tab-menu__item.active{
  @apply text-primaryA;
}

.select-image .empty-placeholder {
  @apply bg-secondaryB flex items-center justify-center rounded-lg cursor-pointer;
}

.select-image .empty-placeholder.disabled {
  @apply bg-secondaryC cursor-default border border-secondaryB;
}

.select-image .empty-placeholder.square {
  width: 160px;
  height: 160px;
}

.select-image img.square {
  width: 160px;
  height: auto;
}

.select-image .empty-placeholder.landscape {
  width: 160px;
  height: 90px;
}

.select-image .empty-placeholder.video {
  width: 320px;
  height: 180px;
}

.select-image .empty-placeholder.video.fullwidth {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  height: auto;
}

.select-image img.landscape {
  width: 320px;
  height: auto;
}

.select-image .empty-placeholder.vertical {
  width: 180px;
  height: 320px;
}

.select-image img.vertical {
  width: auto;
  height: 320px;
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.select-label {
  @apply block mr-8 font-display text-base text-primaryC leading-6 tracking-tight hover:text-primaryA focus:outline-none;
}

.select-label.active {
  @apply text-primaryA;
}

.select-label:first-letter {
  text-transform: uppercase;
}

#video-placeholder video {
  @apply rounded-lg;
}