:root {
  --primaryALight: #e4d5ff;
  --primaryA: #5200e9;
}

.divider{
  @apply w-full h-px bg-secondaryB;
}

.l-first-wave, .r-first-wave{
  animation: first-wave 1.6s infinite;
}

.r-second-wave, .l-second-wave{
  animation: second-wave 1.6s infinite;
}

@keyframes first-wave {
  0%   { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes second-wave {
  0%   { opacity: 0; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}


.react-slideshow-container, .react-slideshow-fade-wrapper, .react-slideshow-fade-images-wrap{
  @apply rounded-b-xl;
  height: 100%;
}

#user-sidebar{
  position: sticky;
  height: calc(100vh - 54px);
  top: 54px;
}


#rooms-sidebar{
  position: sticky;
  height: calc(100vh - 54px);
  top: 54px;
  display: flex;
  flex-direction: column;
}

.rooms-sidebar-link {
  @apply font-display text-base text-primaryC font-bold;
  @apply hover:text-primaryADark;
  @apply flex items-center mb-2;
}

.rooms-sidebar-link.active {
  @apply text-primaryA;
}

.rooms-sidebar-link.disabled {
  @apply pointer-events-none text-secondaryB;
}

.profile-details-box {
  transition: all 0.3s ease-in-out;
}

.profile-details-box.hide {
  height:0;
  transform: translateX(calc(-100% - 64px));
}

.profile-details-edit-box {
  position: absolute;
  top:32px;
  right: 32px;
  bottom: 32px;
  left: 32px;
  height:32px;
  transform: translateX(calc(100% + 64px));
  transition: all 0.3s ease-in-out;
  background-color: white;
  z-index: 10;
}

.profile-details-edit-box.show {
  height: 100%;
  transform: none;
}

.profile-details-code-box.hide {
  transform: translateX(calc(-100% - 64px));
}

.profile-details-email-box, .profile-details-phone-box, .profile-details-code-box {
  position: absolute;
  top:32px;
  right: 32px;
  bottom: 32px;
  left: 32px;
  transition: all 0.3s ease-in-out;
  background-color: white;
}

.profile-details-email-box.hide, .profile-details-phone-box.hide {
  transform: translateX(calc(100% + 64px));
}

.profile-details-email-box.hide.safari, .profile-details-phone-box.hide.safari {
  display: none;
}

.profile-details-email-box.hide-left{
  transform: translateX(calc(-100% - 64px));
}

.black-overlayer{
  position: absolute;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.black-overlayer::before{
  content: '';
  position: absolute;
  z-index: 9;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  border-radius: 0.5rem;
  /* background-color: rgba(0, 0, 0, 0.36); */
}
.achievement-icon{
  @apply mx-auto mb-2;
}
.achievement-icon .main-stop {
  stop-color: rgba(68, 68, 68, 0.2);
}

.achievement-icon .alt-stop {
  stop-color: rgba(68, 68, 68, 0.2);
}
.achievement-icon.active .main-stop {
  animation: mymove 3s forwards;
}

.achievement-icon.active .alt-stop {
  animation: mymove2 3s forwards;
}

@keyframes mymove {
  from {stop-color: rgba(68, 68, 68, 0.2);}
  to {stop-color: #ff004f;}
}

@keyframes mymove2 {
  from {stop-color: rgba(68, 68, 68, 0.2);}
  to {stop-color: #5200e9;}
}

.card-video video {
  @apply object-cover object-center rounded-lg rounded-b-xl;
}

.card-video.absolute-positions video {
  @apply absolute top-0 right-0 bottom-0 left-0 w-full h-full;
}

.refresh-popup {
  position: fixed;
  top: 154px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  white-space: nowrap;
  background-color: #5200E9;
  padding: 8px 16px;
  font-family:  'Inter';
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.35px;
  border-radius: 9px;
  z-index: 20;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.gradient-text {
  background-color: #f3ec78;
  background-image: linear-gradient(101deg, #5200e9 -14%, #ff004c 98%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}

body {
  scrollbar-width: thin;
}

body::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5; */
}

body::-webkit-scrollbar
{
	width: 9px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	background-color: #b3b3b3;
	border: 2px solid #b3b3b3;
  border-radius: 9px;
}

body::-webkit-scrollbar-thumb:hover
{
	background-color: #dadada;
	border: 2px solid #dadada;
  border-radius: 10px;
}

#teaser-placeholder video {
  @apply max-h-48 h-full w-full object-cover shadow-card rounded-lg cursor-pointer;
}

.form-control:focus-within {
  color: var(--primaryALight);
}

input[type=radio] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  -moz-appearance: none;
       appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: var(--primaryALight);
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid var(--primaryALight);
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type=radio]:hover {
  color: var(--primaryA);
  border: 0.15em solid var(--primaryA);
}

input[type=radio]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--primaryALight);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type=radio]:checked::before {
  transform: scale(1);
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.scrollable {
  scrollbar-width: thin;
}

.scrollable::-webkit-scrollbar
{
	width: 9px;
	background-color: #F5F5F5;
}

.scrollable::-webkit-scrollbar-thumb
{
	background-color: #b3b3b3;
	border: 2px solid #b3b3b3;
  border-radius: 9px;
}

.scrollable::-webkit-scrollbar-thumb:hover
{
	background-color: #dadada;
	border: 2px solid #dadada;
  border-radius: 10px;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.player-container {
  padding-bottom: calc(.5625 * 100%);
  height: 0;
  width: 100%;
}

.auto-rows-card-row {
  grid-auto-rows: minmax(0, 330px);
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.auto-rows-creator-row {
  grid-auto-rows: minmax(0, 80px);
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.auto-rows-group-row {
  grid-auto-rows: minmax(0, 220px);
  grid-template-columns: repeat(6, minmax(0, 300px));
}

@media only screen and (max-width: 600px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 360px);
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .auto-rows-creator-row {
    grid-auto-rows: minmax(0, 80px);
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .auto-rows-group-row {
    grid-auto-rows: minmax(0, 200px);
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .row-group-padding {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 600px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 270px);
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .auto-rows-creator-row {
    grid-auto-rows: minmax(0, 80px);
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .auto-rows-group-row {
    grid-auto-rows: minmax(0, 200px);
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .row-group-padding {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 768px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 250px);
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .auto-rows-group-row {
    grid-auto-rows: minmax(0, 200px);
    grid-template-columns: repeat(6, minmax(0, 300px));
  }
}

@media only screen and (min-width: 992px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 260px);
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1100px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 320px);
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .auto-rows-creator-row {
    grid-auto-rows: minmax(0, 80px);
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .auto-rows-group-row {
    grid-auto-rows: minmax(0, 220px);
    grid-template-columns: repeat(9, minmax(0, 300px));
  }
  .row-group-padding {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 1500px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 350px);
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .auto-rows-creator-row {
    grid-auto-rows: minmax(0, 80px);
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .auto-rows-group-row {
    grid-auto-rows: minmax(0, 220px);
    grid-template-columns: repeat(9, minmax(0, 300px));
  }
}

@media only screen and (min-width: 1700px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 320px);
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .auto-rows-group-row {
    grid-auto-rows: minmax(0, 220px);
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1900px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 350px);
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .auto-rows-creator-row {
    grid-auto-rows: minmax(0, 80px);
    grid-template-columns: repeat(15, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1960px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 350px);
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 2160px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 370px);
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 2400px) {
  .auto-rows-card-row {
    grid-auto-rows: minmax(0, 390px);
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.become-creator-grid-sizes {
  /* @apply col-span-6 col-start-4 md:col-span-4 md:col-start-5 */
}

@media only screen and (max-width: 768px) {
  .become-creator-grid-sizes {
    @apply col-span-8 col-start-3
  }
}

@media only screen and (min-width: 768px) {
  .become-creator-grid-sizes {
    @apply col-span-6 col-start-4
  }
}

@media only screen and (min-width: 1440px) {
  .become-creator-grid-sizes {
    @apply col-span-4 col-start-5
  }
}

.cropping-tool-container {
  max-height: calc(100vh - 140px);
}