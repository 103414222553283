.base-input {
  @apply text-base font-paragraph leading-6 tracking-tight py-5 px-4 rounded-lg w-full;
  @apply bg-secondaryC border border-primaryALight;
}

.base-input:focus, .base-input:active {
  @apply outline-none border-primaryA text-primaryC;
}

.base-input::placeholder {
  @apply text-secondaryA;
}

.base-input:focus::placeholder, .base-input:active::placeholder {
  @apply text-primaryC;
}

.base-input:disabled {
  @apply bg-secondaryC border-secondaryB text-secondaryB cursor-default;
}

.input-success, .input-success:focus, .input-success:active {
  @apply bg-positiveLight border-positive;
}

.input-error, .input-error:focus, .input-error:active {
  @apply bg-primaryBlight border-primaryBDark;
}

.input-hint {
  @apply font-display text-sm leading-5 tracking-tight mt-4 text-secondaryB;
}

.input-hint.error {
  @apply text-primaryB;
}

.input-hint.incomplete {
  @apply text-secondaryA;
}

.label-base {
  @apply text-base font-display leading-6 tracking-tight text-primaryC font-normal;
}

.label-sm {
  @apply text-sm font-display leading-5 tracking-tight text-primaryC font-medium;
}

.label-xs {
  @apply text-xs font-display leading-4.2 tracking-tight text-primaryC font-medium;
}

.label-base.disabled {
  @apply text-secondaryB;
}

.label-sm.disabled {
  @apply text-secondaryB;
}

.label-xs.disabled {
  @apply text-secondaryB;
}

.input-icon-right {
  @apply absolute flex items-center justify-center w-16 h-16 top-0 bottom-0 right-2;
}

.input-text-right {
  @apply absolute flex items-center justify-center w-16 h-16 top-0 bottom-0 right-2;
}

.base-input.with-icon {
  @apply pr-18;
}

.base-input.with-rightText {
  @apply pr-18;
}

.base-input.with-flag {
  @apply pl-16;
}

.icon-right {
  @apply fill-current text-primaryA;
}

.icon-right.success {
  @apply text-positive;
}

.icon-right.error {
  @apply text-primaryB;
}

.icon-right.disabled {
  @apply text-secondaryB;
}

.clickable {
  @apply cursor-pointer;
}

.code-input{
  @apply justify-between;
  display: flex !important;
}

.code-input input{
  @apply w-12 h-12 xl:w-14 xl:h-14 2xl:w-17 2xl:h-17 bg-secondaryC border border-primaryALight rounded-lg font-display text-center text-primaryC
  active:border-primaryA active:outline-none 
  focus:border-primaryA focus:outline-none;
}

.code-input input::-webkit-outer-spin-button,
.code-input input::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

.code-input input[type=number] {
  -moz-appearance: textfield;
}

.resend-hint {
  @apply text-right font-display text-sm leading-5 text-primaryC font-medium mt-4;
}